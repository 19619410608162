var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('b-spinner',{staticClass:"position-absolute",staticStyle:{"top":"50%","left":"48%"},attrs:{"variant":"primary","label":"Spinning"}}):_c('b-card',[_c('validation-observer',{ref:"ipamUpdate"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"IP Network"}},[_c('validation-provider',{attrs:{"name":"IP Network","vid":"address","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"IP Network","disabled":_vm.isReadOnly ? true : false,"autocomplete":"off"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Prefix","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Prefix","vid":"prefix","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.isReadOnly ? true : false,"options":_vm.prefixesList,"searchable":false,"reduce":function (prefix) { return prefix.id; },"label":"name"},model:{value:(_vm.form.prefix),callback:function ($$v) {_vm.$set(_vm.form, "prefix", $$v)},expression:"form.prefix"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Allow usage of network and broadcast IP's","label-class":"label-class"}},[_c('b-form-checkbox',{staticClass:"mr-0",attrs:{"switch":"","disabled":_vm.isReadOnly ? true : false,"inline":""},model:{value:(_vm.form.is_hosts_in_range),callback:function ($$v) {_vm.$set(_vm.form, "is_hosts_in_range", $$v)},expression:"form.is_hosts_in_range"}})],1),_c('b-form-group',{attrs:{"label":"Public","label-class":"label-class"}},[_c('b-form-checkbox',{staticClass:"mr-0",attrs:{"switch":"","inline":""},model:{value:(_vm.form.is_public),callback:function ($$v) {_vm.$set(_vm.form, "is_public", $$v)},expression:"form.is_public"}})],1),_c('b-form-group',{attrs:{"label":"Reserved","label-class":"label-class"}},[_c('b-form-checkbox',{staticClass:"mr-0",attrs:{"switch":"","inline":""},model:{value:(_vm.form.is_reserved),callback:function ($$v) {_vm.$set(_vm.form, "is_reserved", $$v)},expression:"form.is_reserved"}})],1),_c('b-form-group',{attrs:{"label":"Name","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Name","vid":"name","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Name","autocomplete":"off"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"NAS","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"NASs","vid":"NASs"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.NASs,"reduce":function (usageType) { return usageType; },"label":"name","disabled":"","clearable":false},model:{value:(_vm.form.nas),callback:function ($$v) {_vm.$set(_vm.form, "nas", $$v)},expression:"form.nas"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Network Type"}},[_c('validation-provider',{attrs:{"name":"Network Type","vid":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.typesList,"reduce":function (type) { return type.id; },"label":"name","searchable":false,"disabled":_vm.isReadOnly ? true : false},on:{"input":function($event){return _vm.getSubnets()}},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Usage Type","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Usage Type","vid":"usage_type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.usageList,"reduce":function (usageType) { return usageType.id; },"label":"name","disabled":_vm.isReadOnly ? true : false,"searchable":false},model:{value:(_vm.form.usage_type),callback:function ($$v) {_vm.$set(_vm.form, "usage_type", $$v)},expression:"form.usage_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Comment","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Comment","vid":"comment","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Comment","autocomplete":"off"},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Network Category","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Network Category","vid":"category_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.categoryList,"reduce":function (category) { return category.id; },"label":"name","searchable":false},model:{value:(_vm.form.category_id),callback:function ($$v) {_vm.$set(_vm.form, "category_id", $$v)},expression:"form.category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.currentRole === 'simrush')?_c('b-form-group',{attrs:{"label":"Owner","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Owner","vid":"company_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.companyList,"label":"name"},on:{"search":function($event){return _vm.onSearchCompanies($event)}},model:{value:(_vm.form.company),callback:function ($$v) {_vm.$set(_vm.form, "company", $$v)},expression:"form.company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1198843148)})],1):_vm._e()],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }