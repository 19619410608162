<template>
  <div>
    <b-spinner
      v-if="loading"
      variant="primary"
      label="Spinning"
      class="position-absolute"
      style="top:50%; left:48%;"
    />
    <b-card v-else>
      <validation-observer ref="ipamUpdate">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group label="IP Network">
                <validation-provider
                  #default="{ errors }"
                  name="IP Network"
                  vid="address"
                  rules="required|max:255"
                >
                  <b-form-input
                    v-model="form.address"
                    :state="errors.length > 0 ? false:null"
                    placeholder="IP Network"
                    :disabled="isReadOnly ? true : false"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Prefix"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Prefix"
                  vid="prefix"
                  rules="required"
                >
                  <v-select
                    v-model="form.prefix"
                    :disabled="isReadOnly ? true : false"
                    :options="prefixesList"
                    :searchable="false"
                    :reduce="prefix => prefix.id"
                    label="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Allow usage of network and broadcast IP's"
                label-class="label-class"
              >
                <b-form-checkbox
                  v-model="form.is_hosts_in_range"
                  class="mr-0"
                  switch
                  :disabled="isReadOnly ? true : false"
                  inline
                />
              </b-form-group>
              <b-form-group
                label="Public"
                label-class="label-class"
              >
                <b-form-checkbox
                  v-model="form.is_public"
                  class="mr-0"
                  switch
                  inline
                />
              </b-form-group>
              <b-form-group
                label="Reserved"
                label-class="label-class"
              >
                <b-form-checkbox
                  v-model="form.is_reserved"
                  class="mr-0"
                  switch
                  inline
                />
              </b-form-group>
              <b-form-group
                label="Name"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="name"
                  rules="required|max:255"
                >
                  <b-form-input
                    v-model="form.name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Name"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="NAS"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="NASs"
                  vid="NASs"
                >
                  <v-select
                    v-model="form.nas"
                    :options="NASs"
                    :reduce="usageType => usageType"
                    label="name"
                    disabled
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Network Type"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Network Type"
                  vid="type"
                  rules="required"
                >
                  <v-select
                    v-model="form.type"
                    :options="typesList"
                    :reduce="type => type.id"
                    label="name"
                    :searchable="false"
                    :disabled="isReadOnly ? true : false"
                    @input="getSubnets()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Usage Type"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Usage Type"
                  vid="usage_type"
                >
                  <v-select
                    v-model="form.usage_type"
                    :options="usageList"
                    :reduce="usageType => usageType.id"
                    label="name"
                    :disabled="isReadOnly ? true : false"
                    :searchable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Comment"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Comment"
                  vid="comment"
                  rules="max:255"
                >
                  <b-form-input
                    v-model="form.comment"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Comment"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Network Category"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Network Category"
                  vid="category_id"
                >
                  <v-select
                    v-model="form.category_id"
                    :options="categoryList"
                    :reduce="category => category.id"
                    label="name"
                    :searchable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="currentRole === 'simrush'"
                label="Owner"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Owner"
                  vid="company_id"
                >
                  <v-select
                    v-model="form.company"
                    :options="companyList"
                    label="name"
                    @search="onSearchCompanies($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- submit button -->
            <b-col
              cols="12"
              class="text-right"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BSpinner, BFormCheckbox,
} from 'bootstrap-vue'
import {
  required, max, integer,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapFields } from 'vuex-map-fields'
import { debounce } from '@/utils/utils'

import { mapActions, mapState } from 'vuex'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BSpinner,
    vSelect,
  },
  data() {
    return {
      loading: false,
      currentRole: '',
      itemId: null,
      form: {
        name: null,
        company: null,
        category_id: null,
        usage_type: null,
        comment: null,
        is_public: null,
        is_reserved: false,
        prefix: null,
        parent_id: null,
        address: null,
        type: null,
        is_hosts_in_range: null,
        nas: {
          id: null,
          name: 'None',
        },
      },
      isReadOnly: false,
      redirectName: null,
      required,
      max,
      integer,
    }
  },
  computed: {
    ...mapState('ipamLists', {
      usageList: 'usageList',
      typesList: 'typesList',
      ipsList: 'ipsList',
      companyList: 'companyList',
      categoryList: 'categoryList',
      prefixesList: 'prefixesList',
    }),
    ...mapFields('ipamLists', [
      'subnetList',
    ]),
    ...mapState('ipamIpv4', {
      item: 'item',
    }),
  },
  async mounted() {
    // eslint-disable-next-line radix
    this.itemId = parseInt(this.$route.params.id)
    this.loading = true
    // eslint-disable-next-line prefer-destructuring
    this.currentRole = JSON.parse(localStorage.getItem('userData')).roles[0]
    if (this.itemId) {
      if (this.$route.meta.navActiveLink === 'ipam-list-ipv4') {
        this.redirectName = 'ipv4'
        await Promise.all([this.getTypes(), this.getUsage(), this.getPrefixes('ip_version=ipv4'), this.getCategories()])
      } else if (this.$route.meta.navActiveLink === 'ipam-list-ipv6') {
        this.redirectName = 'ipv6'
        await Promise.all([this.getTypes(), this.getUsage(), this.getPrefixes('ip_version=ipv6'), this.getCategories()])
      }
      await this.fetchModel(this.itemId)
    } else {
      this.$router.push({ name: 'error-404' })
    }
    this.loading = false
  },
  methods: {
    ...mapActions('ipamIpv4', [
      'updateItem',
      'getItem',
    ]),
    ...mapActions('ipamLists', [
      'getUsage',
      'getList',
      'getTypes',
      'getPrefixes',
      'getIps',
      'getCompanies',
      'getCategories',
    ]),
    async getSubnets() {
      this.form.parent_id = null
      if (this.form.type === 'end') {
        await this.getList(`type=root&ip_version=${this.redirectName}`)
        this.subnetList = this.subnetList.filter(item => item.id !== this.itemId)
      }
    },
    onSearchCompanies(query) {
      if (!query) {
        return
      }
      const payload = {
        query,
      }
      this.search(payload, this.getCompanies)
    },
    search: debounce((payload, fetchAction) => {
      fetchAction({ ...payload })
    }, 350),
    async fetchModel(id) {
      try {
        await this.getItem(id)
        this.form.comment = this.item.comment
        this.form.id = this.item.id
        this.form.ip_version = this.item.ip_version
        this.form.name = this.item.name
        this.form.usage_type = this.item.usage_type
        this.form.is_public = this.item.is_public
        this.form.is_reserved = this.item.is_reserved
        this.form.is_hosts_in_range = this.item.is_hosts_in_range
        this.form.address = this.item.address
        this.form.type = this.item.type
        this.form.prefix = this.item.prefix
        if (this.form.nas) {
          this.form.nas = this.item.nas
        }
        if (this.item.root) {
          this.form.parent_id = this.item.root.id
        }
        this.isReadOnly = this.item.is_read_only
        if (this.item.company) {
          this.form.company = this.item.company
        }
        if (this.item.category) {
          this.form.category_id = this.item.category.id
        }
      } catch (error) {
        const { status } = error.response
        if (status === 404) {
          this.$router.push({ name: 'error-404' })
        }
        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    async validationForm() {
      const valid = await this.$refs.ipamUpdate.validate()
      if (valid) {
        try {
          const obj = { ...this.form }
          delete obj.company
          if (this.form.company) {
            obj.company_id = this.form.company.id
          }
          if (this.item.type === 'end') {
            obj.parent_id = this.item.parent.id
          }
          if (this.isReadOnly) {
            delete obj.address
            delete obj.is_hosts_in_range
            delete obj.prefix
            delete obj.type
            delete obj.is_hosts_in_range
            delete obj.usage_type
          }
          await this.updateItem(obj)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: `ipam-list-${this.redirectName}` })
        } catch (error) {
          const { status } = error.response

          if (!status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }

          if (status === 422) {
            const { fields } = error.response.data

            this.$refs.ipamUpdate.setErrors(fields)
          }
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
